import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '../Views/MainView.vue'),
    children: [
      {
        path: 'mall',
        name: 'maill',
        component: () => import(/* webpackChunkName: "home" */ '../Views/MallView.vue')
      },
      {
        path: 'equity',
        name: 'equity',
        component: () => import(/* webpackChunkName: "equity" */ '../Views/EquityView.vue')
      },
      {
        path: 'my',
        name: 'my',
        component: () => import(/* webpackChunkName: "my" */ '../Views/MyView.vue'),
        children: [
          {
            path: 'withdraw',
            name: 'withdraw',
            component: () => import(/* webpackChunkName: "withdraw" */ '../Views/My/UserWithdrawView.vue')
          },
          {
            path: 'share',
            name: 'share',
            component: () => import(/* webpackChunkName: "share" */ '../Views/My/ShareQRCodeView.vue')
          },
          {
            path: 'balance-log',
            name: 'balance-log',
            component: () => import(/* webpackChunkName: "balance-log" */ '../Views/My/BalanceLogView.vue')
          },
          {
            path: 'withdraw-log',
            name: 'withdraw-log',
            component: () => import(/* webpackChunkName: "withdraw-log" */ '../Views/My/WithdrawLogView.vue')
          },
          {
            path: 'order-list',
            name: 'order-list',
            component: () => import(/* webpackChunkName: "order-list" */ '../Views/My/OrderListView.vue')
          },
          {
            path: 'team-list',
            name: 'team-list',
            component: () => import(/* webpackChunkName: "team-list" */ '../Views/My/TeamListView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../Views/LoginView.vue')
  },
  {
    path: '/register/:referrerUserId',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../Views/RegisterView.vue')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../Views/ResetPasswordView.vue')
  }
];

const Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default Router;
