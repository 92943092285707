<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.my-page .balance-value {
  font-size: 1.4rem !important;
  font-weight: 600;
  color: #666 !important;
}

.my-page .team-number {
  font-size: 1.0rem !important;
  color: #666 !important;
}
</style>
