import { createApp } from 'vue';
import App from './App.vue';
import Router from './Router';
import { Toast, Dialog } from 'vant';
import 'vant/lib/index.css';
import 'vant/es/dialog/style';
import 'vant/es/toast/style';

const app = createApp(App);
app.use(Router);
app.use(Toast);
app.use(Dialog);
app.mount('#app');
